import React, { useState } from "react";
import { API } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function LoaderButton(props) {
  const [isPressed, setIsPressed] = useState(false);

  async function pushButton() {
    setIsPressed(true);
    await API.post('garage-api', '/pushButton')
    setIsPressed(false);
  }

  return (
    <a
      className="btn btn-success btn-block active"
      onClick={pushButton}
      {...props}
    >
      {isPressed && <><FontAwesomeIcon icon={faSpinner} className="fa-pulse fa-fw"/>   </>}
      Activate door
    </a>
  );
}
