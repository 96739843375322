import React, { PureComponent } from 'react';


export default class DoorStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      doorStyle: {color: "gray"},
      doorStatus: "..."
    };
    props.onSocketConnection((s) => {
      s.addEventListener('message', this.onWsMessage);
      s.send(JSON.stringify({'action':'status'}));
    })
//    this.getSocket = props.getSocket;
//    this.connectInterval = null;
  }

//  componentDidMount() {
//    this.checkConnection();
//  }

//  checkConnection = () => {
//    var s = this.getSocket();
//    if (s) {
//      s.addEventListener('message', this.onWsMessage);
//      s.send(JSON.stringify({'action':'status'}));
//    } else {
//      setTimeout(this.checkConnection, 500);
//    }
//  };

  onWsMessage = (e) => {
    var msg = JSON.parse(e.data);
//    console.log(msg);
    if (msg.type === 'status') {
      if (msg.online) {
        if (msg.door === "Closed") {
          this.setState({doorStatus: "Door Closed", doorStyle: {color: "green"}});
        } else if (msg.door === "Open") {
          this.setState({doorStatus: "Door Open", doorStyle: {color: "red"}});
        } else if (msg.door === "Mid") {
          this.setState({doorStatus: "Door Mid", doorStyle: {color: "red"}});
        } else {
          this.setState({doorStatus: "Door Error!", doorStyle: {color: "red"}});
        }
      } else {
        this.setState({doorStatus: "Device Offline", doorStyle: {color: "red"}});
      }
    }
  };

  render() {
    return (
      <h2 className="text-center" id="doorIndicator" style={this.state.doorStyle}>{this.state.doorStatus}</h2>
    );
  }
}