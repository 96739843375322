import React, { PureComponent } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";


export default class LogEntries extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      logList: []
    };
    props.onSocketConnection((s) => {
      s.addEventListener('message', this.onWsMessage);
      s.send(JSON.stringify({'action':'log_entries'}));
    })
    this.tableUpdateInterval = null;
    this.tableColumns = [
      {
        dataField: "timePretty",
        text: "Time",
      },
      {
        dataField: "msg",
        text: "Event"
      }
    ];
  }

  componentDidMount() {
    this.tableUpdateInterval = setInterval(this.updateTableDates.bind(this), 2000);
  }

  componentWillUnmount() {
    clearInterval(this.tableUpdateInterval);
  }

  updateTableDates() {
    var ll = [];
    if (this.state) {
      console.log('updating table');
      for (const entry in this.state.logList) {
        ll.push({"id"         : this.state.logList[entry]["time"],
                 "time"       : this.state.logList[entry]["time"],
                 "timePretty" : this.formatLogDate(this.state.logList[entry]["time"]),
                 "msg"        : this.state.logList[entry]["msg"]})
      }
      this.setState({logList: ll});
    }
  }

  formatLogDate(timestamp) {
    const now = new Date();
    const t = new Date(timestamp * 1000);
    // Do within the last hour
    if (now.getTime()/1000 - timestamp < 30) {
      return "A few seconds ago";
    }
    const hours = '0'+t.getHours();
    const mins = '0'+t.getMinutes();
    const timePrint = hours.substr(-2)+':'+mins.substr(-2);
    if (now.getTime()/1000 - timestamp < 60*60) {
      return Math.floor((now.getTime()/1000 - timestamp)/60) + " minutes ago (" + timePrint + ")";
    }
    // Do today
    if ((now.getDate() === t.getDate()) & (now.getMonth() === t.getMonth())) {
      return "Today, at " + timePrint;
    }
    // Do yesterday
    var y1 = new Date();
    y1.setDate(y1.getDate() - 1);
    if ((y1.getDate() === t.getDate()) & (y1.getMonth() === t.getMonth())) {
      return "Yesterday, at " + timePrint;
    }
    // Everything else
    const timestampDate = t.getDate() + '/' + (t.getMonth()+1);
    return timestampDate + ", " + timePrint;
  }

  onWsMessage = (e) => {
    var msg = JSON.parse(e.data);
    if (msg.type === 'all_log_entries') {
      var logList = [];
      for (const entry in msg.entries) {
        logList.push({"id"         : msg.entries[entry]["time"],
                      "time"       : msg.entries[entry]["time"],
                      "timePretty" : this.formatLogDate(msg.entries[entry]["time"]),
                      "msg"        : msg.entries[entry]["msg"]})
      }
      this.setState({logList: logList});
    }
    if (msg.type === 'new_log_entry') {
      var s = [];
      if (this.state.logList.length < 10) {
        s = this.state.logList.slice();
      } else {
        s = this.state.logList.slice(0, -1);
      }
      s.unshift({"id"         : msg.entry["time"],
                 "time"       : msg.entry["time"],
                 "timePretty" : this.formatLogDate(msg.entry["time"]),
                 "msg"        : msg.entry["msg"]});
      this.setState({logList: s});
    }
  };

  render() {
    return (
      <>
        <div className="LogEntries">
          <BootstrapTable striped keyField="id" data={this.state.logList} columns={this.tableColumns} />
        </div>
      </>
    );
  }
}