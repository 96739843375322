const config = {
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://garage-api.penguinseatcheese.co.uk"
  },
  wsApiGateway: {
    URL: "wss://garage-ws.penguinseatcheese.co.uk/"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_J869znves",
    APP_CLIENT_ID: "5bir9u9fm8u0rn2jrfnrif80gg",
    IDENTITY_POOL_ID: "eu-west-1:1ea205cc-c9ba-4403-ae28-fcec54a03d87"
  }
};

export default {
  // Add common config values here
  ...config
};
